<template>
  <section class="reports">
    <v-form
      ref="form"
      v-model="isValid"
      class="reports__form"
      @submit.prevent="submit"
    >
      <v-row class="align-center justify-center">
        <v-col cols="6">
          <v-text-field
            v-model="search"
            :rules="validation.orderNumber"
            class="reports__field"
            label="Поиск груза по номеру накладной"
          />
        </v-col>
        <v-col cols="2">
          <v-btn class="mr-3 reports__btn" type="submit" color="primary">
            Поиск
          </v-btn>
          <v-btn class="reports__btn" @click="reset"> Сброс </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-row class="mt-10 d-flex justify-center">
      <v-col cols="8">
        <v-data-table
          v-if="showTable"
          class="reports__table"
          :headers="headers"
          :items="items"
          hide-default-footer
          :loading="loading"
        />
      </v-col>
    </v-row>
  </section>
</template>

<script>
export default {
  data() {
    return {
      isValid: true,
      search: '',
      showTable: false,
      loading: false,
      headers: [
        { text: 'Дата', value: 'date' },
        { text: 'Статус', value: 'statusName' },
      ],
      validation: {
        orderNumber: [(v) => /\d{10}/g.test(v) || ''],
      },
      items: [],
    };
  },
  async created() {
    try {
      await this.$store.dispatch('loadOrders');
    } catch (error) {
      this.showAlert(error);
    }
  },
  methods: {
    async submit() {
      this.$refs.form.validate();
      if (!this.isValid) {
        return;
      }
      const order = this.$store.getters.currentOrderList.find(
        (order) => order.number === this.search
      );
      try {
        if (!order) {
          throw new Error('Введите существующий номер накладной');
        }
        this.$store.commit('setLoader');
        this.items = await this.$store.dispatch('getOrderInfo', order.id);
        this.showTable = true;
      } catch (error) {
        this.showAlert(error);
      } finally {
        this.$store.commit('deleteLoader');
      }
    },
    reset() {
      this.$refs.form.reset();
      this.search = '';
      this.showTable = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.reports {
  &__table {
    width: 100%;
  }
}
</style>
